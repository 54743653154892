@import "partials/variables";
@import "partials/mixins";




.dashboard-content{
    .custom-card-wapper {
        padding-right: 10px;
        .custom-card-list {
            background: #FFFFFF;
            border-radius: 15px;
            padding: 34px 25px;
            display: flex;

            .custom-card-icon-box {
                margin-right: 12px;
                .custom-card-icon {
                    width: 57px;
                }
            }

            .card-list-count {
                font-family: 'sf-bold';
                font-size: 29px;
                color: #330948;
                text-align: left;
                margin-bottom: 5px;
            }

            .card-list-count-description {
                font-family: 'sf-medium';
                font-size: 15px;
                text-align: left;
                color: #330948;
                line-height: 18px;
            }
        }

    }

    .chart-card-bg{
        background: #FFFFFF;
        border-radius: 15px;
        padding: 30px;
        margin-top: 20px;
        min-height: 499px;

        .select-wrapper {
            position: relative;

            .form-control {
                background-color: #FFFFFF;
                border: 1px solid #E9EDF0;
                border-radius: 6px;
                width: 118px;
                height: 41px;
                position: absolute;
                top: 0px;
                right: 0px;
                z-index: 99;
                font-size: 14px;
            }
        }
    }
}

@media ($sm-1679) {
    .dashboard-content .custom-card-wapper .custom-card-list .custom-card-icon-box {
        margin-right: 8px;
    }
    .dashboard-content .custom-card-wapper .custom-card-list {
        padding: 34px 22px;
    }

    .dashboard-content .custom-card-wapper .custom-card-list {
        padding: 25px 21px;
    }

    .dashboard-content .custom-card-wapper .custom-card-list .card-list-count-description {
        font-size: 12px;
    }

    .dashboard-content .custom-card-wapper .custom-card-list .card-list-count {
        font-size: 25px;
        margin: 0;
    }
}

@media ($sm-1441) {
    .dashboard-content .custom-card-wapper .custom-card-list {
        padding: 20px 18px;
    }

    .dashboard-content .custom-card-wapper .custom-card-list .custom-card-icon-box {
        margin-right: 5px;
    }
    .dashboard-content .custom-card-wapper .custom-card-list .custom-card-icon-box .custom-card-icon{
        width: 44px;
    }
    .dashboard-content .custom-card-wapper .custom-card-list .card-list-count {
        font-size: 22px;
    }
}

@media ($sm-1281) {
    .dashboard-content .custom-card-wapper .custom-card-list {
        padding: 20px 15px;
    }

    .dashboard-content .custom-card-wapper .custom-card-list .custom-card-icon-box .custom-card-icon-box{
        width: 40px;
    }

    .dashboard-content .custom-card-wapper .custom-card-list .card-list-count {
        font-size: 18px;
    }
}

@media ($sm-1023) {
    .dashboard-content .custom-card-wapper .custom-card-list .card-list-count {
        font-size: 16px;
    }

    .dashboard-content .custom-card-wapper .custom-card-list {
        padding: 15px 10px;
    }
}

@media ($sm-991) {
    .dashboard-content .custom-card-wapper .custom-card-list {
        margin-bottom: 15px;
    }
}
@media ($sm-479) {
    .dashboard-content .custom-card-wapper .custom-card-list{
        display: block;
    }
    .dashboard-content .custom-card-wapper .custom-card-list .custom-card-icon-box{
        margin-bottom: 8px;
    }
}